export const isValidEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const checkIsNull = (value) => {
  if (!value || value.trim() === '') return true;
  return false;
};

export const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  window.location.replace('/');
};

export function getPastMonths(pastMonthCount) {
  // Get the current UTC date
  const currentDate = new Date();

  // Convert to UTC format (start of the current month)
  const currentMonthUTC = new Date(Date.UTC(
    currentDate.getUTCFullYear(),
    currentDate.getUTCMonth(),
    1
  ));

  const pastMonths = [];

  // Loop to generate the past months
  for (let i = 0; i < pastMonthCount; i++) {
    // Decrease the month by 1 in UTC
    currentMonthUTC.setUTCMonth(currentMonthUTC.getUTCMonth() - 1);

    const month = String(currentMonthUTC.getUTCMonth() + 1).padStart(2, '0'); 
    const year = currentMonthUTC.getUTCFullYear();

    pastMonths.push(`${month}/${year}`);
  }

  return pastMonths;
}


export function getMonthsBetween(startMonth, endMonth) {
  // Convert the start and end months to Date objects in UTC
  const [startYearStr, startMonthStr] = startMonth.split('-');
  const [endYearStr, endMonthStr] = endMonth.split('-');

  // Create Date objects for start and end months
  const startDate = new Date(Date.UTC(parseInt(startYearStr), parseInt(startMonthStr) - 1, 1)); // Start month
  const endDate = new Date(Date.UTC(parseInt(endYearStr), parseInt(endMonthStr) - 1, 1)); // End month

  const months = [];

  // Loop to generate the months in reverse order from endMonth to startMonth
  while (endDate >= startDate) {
    const month = String(endDate.getUTCMonth() + 1).padStart(2, '0'); // Get month (1-12) and format
    const year = endDate.getUTCFullYear();

    // Push formatted month to the array in "mm/yyyy" format
    months.push(`${month}/${year}`);

    // Move to the previous month
    endDate.setUTCMonth(endDate.getUTCMonth() - 1);
  }

  return months;
}
