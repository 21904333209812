import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
// import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
// import LogoImg from "../../public/logo/everlong_logo.png"
// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx, width = 40, height = 10 }) {
  // const theme = useTheme();
  // const PRIMARY_LIGHT = theme.palette.primary.light;
  // const PRIMARY_MAIN = theme.palette.primary.main;
  // const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box
      sx={{
        width: { xs: width / 2, sm: width, md: width * 1.5 },
        height: { xs: height / 2, sm: height, md: height * 1.5 },
        ...sx,
      }}
    >
      <img
        src="/logo/logo_full.png"
        alt="logo"
        style={{ width: '100%', height: '100%' }}
      />
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
