// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  user: getIcon('ic_user'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dietition: getIcon('ic_dietition'),
  dashboard: getIcon('ic_dashboard'),
};

const userData = localStorage.getItem('user');
const parsedUserData = JSON.parse(userData);
const role = parsedUserData?.role || '';
const sidebarConfig = [
  {
    // subheader: 'general v3.3.0',
    items: role === 'dietitian' ? [{ title: 'CarePIs', path: '/carepis', icon: ICONS.dietition }] : [
      { title: 'Employees', path: '/employees', icon: ICONS.user },
      { title: 'Payroll', path: '/payroll', icon: ICONS.ecommerce },
      { title: 'Weekly averages', path: '/weekly-averages', icon: ICONS.analytics },
      { title: 'CarePIs', path: '/carepis', icon: ICONS.dietition },
    ],
  },
];

export default sidebarConfig;
